import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import QRCode from "react-qr-code";

const PaymentPage = () => {
  const { state } = useLocation();
  const { startDate, timeSlot, userData } = state || {};

  const [qrIsVisible, setQrIsVisible] = useState(false);
  const [url, setUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showChessPayment, setShowChessPayment] = useState(false);
  const [chessPaymentMessage, setChessPaymentMessage] = useState("");

  const generatedInvnos = new Set();

  function generateUniqueInvno() {
    let newInvno;
    do {
      newInvno =
        "IOP-TEST-SAJD-" +
        Math.floor(Math.random() * 1e16)
          .toString()
          .padStart(16, "0");
    } while (generatedInvnos.has(newInvno));
    generatedInvnos.add(newInvno);
    return newInvno;
  }

  const handleSubmit = async () => {
    const paymentDetails = {
      status: "pending",
      mId: "SBKYKewQFJA=RkZGRlJYWFFQV0lOTVhFTUE=",
      invno: generateUniqueInvno(),
      fName: userData.name,
      lName: userData.name,
      mNo: userData.phone ? userData.phone : "8888888888",
      currency: "INR",
      amount: 10,
    };

    try {
      const response = await fetch(
        "https://indiaonlinepay.com/api/iopregisterupiintent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            opStatus: "1",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(paymentDetails),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setShowChessPayment(true);
        console.log("Payment successful:", data);
        setUrl(data.intent);
      } else {
        console.error("Payment failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error making payment request:", error);
    }
  };

  useEffect(() => {
    let timer;
    if (qrIsVisible) {
      timer = setTimeout(() => {
        setQrIsVisible(false);
      }, 60000);
    }
    return () => clearTimeout(timer);
  }, [qrIsVisible]);

  useEffect(() => {
    handleQrCodeGenerator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    let interval;
    if (qrIsVisible) {
      interval = setInterval(() => {
        axios
          .get("https://playnikunjstar.com:5001/api/transaction-status")
          .then(async (response) => {
            setChessPaymentMessage(response.data.status);
          })
          .catch((error) => {
            console.error("Error fetching transaction status:", error);
          });
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [qrIsVisible]);

  const handleQrCodeGenerator = () => {
    if (!url) {
      setErrorMessage("Please enter a URL.");
      return;
    }
    setErrorMessage("");
    setQrIsVisible(true);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Payment Page</h2>
        {state ? (
          <div>
            <p className="mb-2">
              <strong>Date:</strong> {startDate?.toLocaleDateString()}
            </p>
            <p className="mb-2">
              <strong>Time Slot:</strong> {timeSlot}
            </p>
            <p className="mb-2">
              <strong>Name:</strong> {userData.name}
            </p>
            <p className="mb-2">
              <strong>Email:</strong> {userData.email}
            </p>
            <p className="mb-4">
              <strong>Phone:</strong> {userData.phone}
            </p>
          </div>
        ) : (
          <p className="text-red-500">No booking details provided!</p>
        )}
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white py-2 px-4 rounded w-full hover:bg-green-600"
        >
          Pay Now
        </button>
      </div>
      {showChessPayment && (
        <div className="absolute inset-0 flex items-center justify-center flex-col bg-gray-100 shadow-lg shadow-gray-800 rounded-lg z-50 w-[500px] left-auto right-auto p-8 min-h-[370px] h-full top-1/2 -translate-y-2/4">
          <h4 className="text-primary text-2xl font-Kanit font-medium leading-6 mb-6">
            QR Code Generator
          </h4>
          <form className="flex items-center justify-center flex-col">
            {errorMessage && (
              <div className="text-red-500 mb-4 text-sm">{errorMessage}</div>
            )}
            {qrIsVisible && (
              <div className="mt-4">
                <QRCode value={url} size={100} />
              </div>
            )}
            {chessPaymentMessage && (
              <div className="mt-4 text-green-500">
                Transaction Status: {chessPaymentMessage}
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
